import axios, { AxiosError, type AxiosProgressEvent } from 'axios';
import { ElMessageBox } from 'element-plus';
import RequestTimeout from './RequestTimeout';
import { getFromLocalStorage, setToLocalStorage } from '../functions';
import { useUserStore } from "~/store/UserModule";

export const enum BASE_METHOD {
  'GET' = 'GET',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'DELETE' = 'DELETE',
}

export const enum FetchApiOrigin {
  'DATA_API_ORIGIN' = 'DATA_API_ORIGIN',
  'LIGHT_API_ORIGIN' = 'LIGHT_API_ORIGIN',
}

type FetchApiOptionsCommon = {
  params?: { [key: string]: number | boolean | string | null };
  body?: unknown;
  method?: BASE_METHOD;
};

type FetchApiOptions = FetchApiOptionsCommon & {
  origin?: FetchApiOrigin;
  endpoint: string;
  server?: boolean;
  onUploadProgress?: (process: AxiosProgressEvent) => void;
  isFullScreenLoading?: boolean;
};

type $FetchApiOptions = FetchApiOptionsCommon & {
  url: string;
  headers: Record<string, string>;
};

const commonHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' };

const key = 'accessToken';

const unwrapResponse = (response: unknown): unknown => {
  if (!(response instanceof Object)) return response;
  if (!('data' in response)) return response;
  return unwrapResponse(response.data);
};

export class ApiKey {
  static get() {
    return useCookie(key).value ?? '';
  }

  static set(payload: string) {
    useCookie(key).value = payload;
  }
}

const $fetchApi = async ({
  url: $fetchApiUrl,
  params,
  body,
  method,
  headers,
}: $FetchApiOptions) => {
  const response = await $fetch($fetchApiUrl, {
    method,
    params,
    body: body as BodyInit,
    headers,
  });

  return unwrapResponse(response);
};

const clientErrorToMessage = (responseData: object) => {
  let message = 'Не удалось установить соединение с сервером';
  if (responseData && typeof responseData === 'object') {
    if ('errors' in responseData) {
      message = Object.values(responseData.errors ?? {}).join(', ');
    }
    if ('message' in responseData) {
      message = responseData.message as string;
    }
  }
  return message;
};

const onClientError = async (axiosError: AxiosError) => {
  const responseData = axiosError?.response?.data as object;
  const message = clientErrorToMessage(responseData);
  const route = useRoute();

  try {
    if (axiosError.response?.status === 401) {
      if (['adv-main', 'adv-place', 'adv-catalog', 'adv-news'].includes(route.name as string)) return;
      const userStore = useUserStore();
      ApiKey.get() ? null : userStore.loginDialogVisible ? null : userStore.loginDialogVisible = true
    } else {
      return await ElMessageBox.alert(message, {
        title: 'Ошибка при выполнении запроса',
        showCancelButton: true,
        cancelButtonText: 'На главную',
        distinguishCancelAndClose: true,
      });
    }
  } catch (reason) {
    if (reason === 'cancel') navigateTo('/');
  }
};

export const fetchApi = async <T = unknown>({
  origin = FetchApiOrigin.LIGHT_API_ORIGIN,
  params = {},
  body = undefined,
  method = BASE_METHOD.GET,
  endpoint = '',
  onUploadProgress = undefined,
  isFullScreenLoading = true,
}: FetchApiOptions) => {
  const headers = {
    ...commonHeaders,
    'Content-Type': onUploadProgress ? 'multipart/form-data' : commonHeaders['Content-Type'],
    // "X-Api-Key": ApiKey.get(),
    Authorization: `Bearer ${ApiKey.get()}`,
  };

  const url = new URL(`${useRuntimeConfig().public[origin]}${endpoint}`).href;
  if (process.server) {
    return $fetchApi({
      params,
      body,
      method,
      url,
      headers,
    }) as Promise<T>;
  }

  let requestTimeout: RequestTimeout | null = null;
  if (isFullScreenLoading) {
    requestTimeout = new RequestTimeout(url);
  }

  try {
    const data = await axios.request({
      method,
      url,
      params,
      headers,
      data: body,
      onUploadProgress,
    });

    return unwrapResponse(data) as T;
  } catch (error) {
    onClientError(error as AxiosError);
    throw error instanceof Error ? error : new Error(error as string);
  } finally {
    if (isFullScreenLoading && requestTimeout) {
      requestTimeout.finish();
    }
  }
};
