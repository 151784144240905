import { defineStore } from "pinia";
import { ElNotification, ElMessageBox } from "element-plus";
import { UserStatus } from "~/interfaces/user";
import { ApiKey } from "~/common/API/baseApi.js";

const runtimeConfig = useRuntimeConfig();

const checkTgAuthLink = "/user/telegram/bot/check-auth";

//  https://api.stage-data-light.socialjet.pro/user/telegram/bot/check-auth?authKey=Data-Light-b600d5907a79a4c46e633fd245e8720817304579007185

const timeoutValueToCheckTg = 60000;
const intervalValueToCheckTg = 3000;

export const useUserStore = defineStore("user", () => {
  const id: Ref<string> = ref("");
  const name: Ref<string> = ref("");
  let status = UserStatus.REGISTERED;
  const telegram: Ref<string> = ref("");
  const email: Ref<string> = ref("");
  const emailConfirmed: Ref<boolean> = ref(false);
  const authKey: Ref<string> = ref("");
  const authorized: Ref<string> = ref("");
  const user: Ref<any> = ref(null);
  const intervalIdToTgCheck: Ref<number> = ref(0);
  const timeoutIdToTgCheck: Ref<number> = ref(0);

  const loginDialogVisible: Ref<boolean> = ref(false);

  // Взял из стока, зачем пока не понятно
  function setStatus(data: UserStatus) {
    status = data;
  }

  function setLS() {
    localStorage.setItem("useUserStore__authKey", authKey.value);
    localStorage.setItem("useUserStore__authorized", authorized.value);
    localStorage.setItem("useUserStore__id", id.value);
  } // setLS

  // 1: запросить host
  // stage-data-light.socialjet.pro
  // /user/telegram/bot/generate-auth-key
  function resetAuthKey() {
    authKey.value = "";
    authorized.value = "";
    id.value = "";
    setLS();
  } // resetAuthKey

  function setAuthorized(key: string, auth: string, idV: string) {
    authKey.value = key;
    authorized.value = auth;
    id.value = idV;
    setLS();
  } // setAuthorized

  function requestAuthKey() {
    const useUserStoreAuthorized = localStorage.getItem(
      "useUserStore__authorized",
    );
    if (useUserStoreAuthorized) authorized.value = useUserStoreAuthorized;

    const useUserStoreAuthKey = localStorage.getItem("useUserStore__authKey");
    if (useUserStoreAuthKey) authKey.value = useUserStoreAuthKey;

    const useUserStoreId = localStorage.getItem("useUserStore__id");
    if (useUserStoreId) id.value = useUserStoreId;

    if (authorized.value) return;
    if (authKey.value && authKey.value !== "undefined") return;

    useFetch("/user/telegram/bot/generate-auth-key", {
      baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
      method: "POST",
      onResponse({ response }) {
        authKey.value = response._data.authKey;
        localStorage.setItem("useUserStore__authKey", authKey.value);
      },
    });
  } // requestAuthKey

  function checkTelegramAuthorize() {
    // new check
    clearInterval(intervalIdToTgCheck.value);
    clearTimeout(timeoutIdToTgCheck.value);

    timeoutIdToTgCheck.value = window.setTimeout(() => {
      clearInterval(intervalIdToTgCheck.value);
    }, timeoutValueToCheckTg);

    intervalIdToTgCheck.value = window.setInterval(() => {
      if (authorized.value && id.value) {
        clearInterval(intervalIdToTgCheck.value);
      }
      useFetch(checkTgAuthLink, {
        baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
        method: "GET",
        query: {
          authKey: authKey.value,
        },
        onResponse({ response }) {
          // { id: number; accessToken: string }
          if (response._data.accessToken && response._data.id) {
            authorized.value = response._data.accessToken;
            ApiKey.set(response._data.accessToken);
            id.value = response._data.id;
            ElNotification({
              title: "Успешная авторизация через Telegram",
              message: "Спасибо!",
              type: "success",
            });
            setLS();
            clearInterval(intervalIdToTgCheck.value);
            clearTimeout(timeoutIdToTgCheck.value);
          } else {
            ElNotification({
              title: "Ожидание авторизации",
              message: "Ждём подтверждения от бота...",
              type: "warning",
            });
          }
        },
      });
    }, intervalValueToCheckTg);
  } // checkTelegramAuthorize

  function startTelegramAuthorize() {
    // ElMessageBox.confirm(
    //   /* title: */ "Вход через Telegram",
    //   /* message: */ "Перейдите в Telegram и нажмите start в боте",
    //   {
    //     showCancelButton: false,
    //     confirmButtonText: "OK",
    //   },
    // ).then(() => {
      const url = `${
        useRuntimeConfig().public.RESOLVE_TELEGRAM_AUTHORIZE_PREFIX
      }${authKey.value}`;
      console.log("check url for telegram bot login:", url);
      window.open(url, "_blank");
      loginDialogVisible.value = false;
      checkTelegramAuthorize();
    // });
  } // startTelegramAuthorize

  return {
    id,
    name,
    status,
    telegram,
    email,
    emailConfirmed,
    user,
    authKey,
    authorized,
    loginDialogVisible,
    intervalIdToTgCheck,
    timeoutIdToTgCheck,
    setLS,
    requestAuthKey,
    resetAuthKey,
    setAuthorized,
    startTelegramAuthorize,
    checkTelegramAuthorize,
    setStatus,
  };
});
